
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'

type Props = {
    chartData: any,
    handleCloseEvent: any,
}

const FlowVolumeLoopComponent: React.FC<Props> = ({ chartData, handleCloseEvent }) => {

    const chartRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {// Load Component

        if (!chartRef.current)
            return

        var findSwitchToInhalation = 0
        var exHealingChartDatas = []
        var inHealingChartDatas = []
        var volumeChartDatas = []
        var findPeakExpiratoryValue = 0
        var findPeakExpiratoryX = 0.0
        var findPeakExpiratoryY = 0.0

        var volumeCumulative = 0
        var findFev1Index = 0
        var findFev1Value = 0
        console.log("---------------------------------------------------")

        if (chartData.flow_rate != undefined) {

            console.log("FEV1: Start Find FEV1. Time Delta Total Index Size " + chartData.time_delta.length)
            var tmpTimeCumulative = 0
            for (var i in chartData.time_delta) {
                var timeValue = chartData.time_delta[i]
                tmpTimeCumulative += timeValue
                if (tmpTimeCumulative <= 1) {
                    findFev1Index = parseInt(i) + 1
                    console.log("TIME: Index:", i, " Delta : ", timeValue, " Cumulative : ", tmpTimeCumulative, " Fev index is Searching : ", findFev1Index)
                } else {

                    console.log("TIME: Index:", i, " Delta : ", timeValue, " Cumulative : ", tmpTimeCumulative, " Fev index was Found : ", findFev1Index)
                }
            }

            console.log("FEV1: Index Found : " + findFev1Index, " Fev1 Elapsed Time : 1. Total Elapsed Time : ", tmpTimeCumulative)

            console.log("FLOW RATE: Start.")
            var tmpTimeCumulative = 0
            for (var i in chartData.flow_rate) {
                tmpTimeCumulative += chartData.time_delta[i] // Only use For Console Log

                var flowValue = chartData.flow_rate[i]

                console.log("FLOW RATE: Index:", i, " Value : ", flowValue + " L/sec", "Matched Cumulative Time : ", tmpTimeCumulative)

                if (parseFloat(flowValue) < 0 && findSwitchToInhalation == 0) {
                    findSwitchToInhalation = parseInt(i)
                    console.log("In Healing After This Index :", i, "Delta : ", flowValue)
                }

                if (findSwitchToInhalation == 0) {
                    volumeCumulative += chartData.total_flow[i]
                    console.log("volumeCumulative Ex: Detal ", chartData.total_flow[i])
                    volumeChartDatas.push(chartData.total_flow[i])
                    exHealingChartDatas.push(flowValue)

                    // Find Peak Value By Total Flow Cumulative
                    if (findPeakExpiratoryValue < flowValue) {
                        findPeakExpiratoryValue = flowValue
                        findPeakExpiratoryX = chartData.total_flow[i]
                        findPeakExpiratoryY = parseFloat(flowValue)
                    }

                    inHealingChartDatas.push(0)
                } else {
                    console.log("volumeCumulative InHealing:", chartData.total_flow[i])
                    volumeCumulative += chartData.total_flow[i]
                    volumeChartDatas.push(chartData.total_flow[i])
                    inHealingChartDatas.push(flowValue)
                    //totalFlowCumulative -= chartData.total_flow[i]
                    //flowChart.push(totalFlowCumulative.toFixed(2))
                }
            }
        }

        findFev1Value = volumeChartDatas[findFev1Index]
        console.log("FEV1 : FEV1 Liter Value On Flow", findFev1Value = volumeChartDatas[findFev1Index])
        console.log("PEF: findPeakExpiratoryX:", findPeakExpiratoryX+" Volume(Lt)", "findPeakExpiratoryY:", findPeakExpiratoryY+" Flow(Lt/s)")
 

        var options = {
            series: [{
                name: "Exhalatıon",
                data: exHealingChartDatas
            }, {
                name: "Inhalation",
                data: inHealingChartDatas.reverse().splice(0, exHealingChartDatas.length)
            }],
            labels: volumeChartDatas.splice(0, exHealingChartDatas.length),

            chart: {
                height: 400,
                type: 'line',
                id: 'areachart-2',
            },
            annotations: {
                yaxis: [{
                    y: 0,
                    y2: -5,
                    borderColor: '#000',
                    fillColor: '#DCDCDC',
                    opacity: 0.2,
                    label: {
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#DCDCDC',
                        },
                        position: 'right',
                        offsetY: 60,
                        textAnchor: "end",
                        text: 'FVC',
                    }
                }],
                xaxis: [{
                    x: findFev1Value,
                    strokeDashArray: 0,
                    borderColor: '#775DD0',
                    label: {
                        offsetY: 10,
                        borderColor: '#775DD0',
                        style: {
                            color: '#fff',
                            background: '#775DD0',
                        },
                        text: 'FEV1',
                    }
                }],
                points: [{
                    x: findPeakExpiratoryX,
                    y: findPeakExpiratoryY,
                    marker: {
                        size: 8,
                        fillColor: '#fff',
                        strokeColor: 'red',
                        radius: 2,
                        cssClass: 'apexcharts-custom-class'
                    },
                    label: {
                        borderColor: '#BB2E39',
                        offsetY: 0,
                        offsetX: 0,
                        style: {
                            color: '#fff',
                            background: '#BB2E39',
                        },

                        text: 'PEF',
                    }
                }]
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            grid: {
                padding: {
                    right: 30,
                    left: 20
                }
            },
            xaxis: {
                title: {
                    text: 'Volume (liters)'
                },
                type: 'numeric',
                labels: {
                    formatter: function (value: number) {
                        return value.toFixed(2) + "L";
                    }
                }
            },
            yaxis: {
                title: {
                    text: 'Flow (liters/second)'
                },
                type: 'numeric',
                labels: {
                    formatter: function (value: number) {
                        return value.toFixed(2) + "L/sec";
                    }
                }
            },
            legend: {
                position: "bottom",
                horizontalAlign: "center",
            },
        };
        const chart = new ApexCharts(chartRef.current, options)
        if (chart) {
            chart.render()
        }

        return () => { // Unload Component
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    return (
        <div style={{ position: "fixed", zIndex: 9999999, background: "#000000AA", width: "100%", height: "100%", top: 0, left: 0 }}>
            <div className={`card mb-5`} style={{ width: "550px", position: 'fixed', top: "210px", left: "35%" }}>
                <div className='d-flex justify-content-between' style={{ background: "#F4F3F3", padding: "16px 24px", fontSize: "16px" }}>
                    <span>Flow-Volume Loop</span>
                    <span><img onClick={() => handleCloseEvent()} src='/media/icons/custom/close_btn.svg' alt="Pollen" style={{ cursor: "pointer", padding: "5px" }} /></span>
                </div>
                <div className='d-flex'>
                    <div style={{ padding: "16px 5px 0px 24px", fontSize: "16px" }}>
                        {chartData.patient_name}
                    </div>
                    <div style={{ padding: "20px 0px ", fontSize: "12px", color: "rgba(5, 7, 10, 0.6);" }}>
                        {chartData.register_date != null && typeof (chartData) != undefined ? new Date(chartData.register_date).toLocaleDateString('en-Us', { month: 'short', day: 'numeric', year: 'numeric' }) : "-"}
                    </div>
                </div>
                {/* begin::Body */}
                <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden p-10 pt-0'>
                    {/* begin::Chart */}
                    <div ref={chartRef} className='mixed-widget-10-chart'></div>
                    {/* end::Chart */}
                </div>
            </div>
        </div>
    )
}

export { FlowVolumeLoopComponent }

