/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  className: string
  title: string
  change: string
  color: string
}

const StatWidget: React.FC<Props> = ({ className, title, change, color }) => {

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        <div className='d-flex flex-stack flex-grow-1 align-items-start' style={{padding:"16px 24px"}}>
          <div className='d-flex flex-column me-2'>
            <div className='text-dark fs-6'>
              {title}
            </div>
          </div>

          <span className={`statNumber`}>
            {change}
          </span>
        </div>

      </div>
      {/* end::Body */}
    </div>
  )
}

export { StatWidget }
