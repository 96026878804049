/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { ChartWidget } from './components/Chart'
import { StatWidget } from './components/Stat'
import { JournalTableComponent } from '../../shared/Table/JournalsTable'
import axios from 'axios'
import { Link } from 'react-router-dom'
import './Dashboard.scss'
import { JournalAthleteTableComponent } from '../../shared/Table/JournalAthleteTable'

const DashboardPage: FC = () => {

  const DATA_URL = process.env.REACT_APP_API_URL + "/api/journal/stat"

  const [patientCount, setPatientCount] = useState("...");
  const [athleteCount, setAthleteCount] = useState("...");
  const [journalCount, setJournalCount] = useState("...");
  const [feedbackCount, setFeedbackCount] = useState("...");
  const [chartSeriesData, setChartSeriesData] = useState([]);
  const [activeTab, setActiveTab] = useState("asthmatic")

  useEffect(() => {

    axios.post(DATA_URL, {
      "filter": {},
    }).then(function (res) {
      setPatientCount(res.data.patientCount.total);
      setAthleteCount(res.data.athleteCount.total);
      setJournalCount(res.data.journalCount.total);
      setFeedbackCount(res.data.feedbackCount.total);
      setChartSeriesData(res.data.chartData);
    }).finally(() => {
    })

  }, [DATA_URL]);

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <StatWidget
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            color='primary'
            title='Journal Entries'
            change={journalCount}
          />

          <Link to={"/feedback"}>
            <StatWidget
              className='card-xxl-stretch-50  mb-5 mb-xl-8'
              color='primary'
              title='Feedback Entries'
              change={feedbackCount}
            />
          </Link>
        </div>
        <div className='col-xxl-4' style={{ maxWidth: 450 }}>
          <ChartWidget
            className='mb-5 mb-xl-8'
            chartHeight='155px'
            chartData={chartSeriesData}
          />
        </div>
        <div className='col-xxl-4'>
          <StatWidget
            className='card-xxl-stretch-50  mb-5 mb-xl-8'
            color='primary'
            title='Asthmatic Users'
            change={patientCount}
          />
          <StatWidget
            className='card-xxl-stretch-50  mb-5 mb-xl-8'
            color='primary'
            title='Performance Athlete Users'
            change={athleteCount}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>

        <div className='col-xl-12'>
          <div className='tabContainer'>
            <div onClick={() => setActiveTab("asthmatic")} className={`tabContent ${activeTab == "asthmatic" ? "active" : ""}`}>
              Asthmatic
            </div>
            <div onClick={() => setActiveTab("athlete")} className={`tabContent ${activeTab == "athlete" ? "active" : ""}`}>
              Performance Athlete
            </div>
          </div>
          {activeTab == "asthmatic" && <JournalTableComponent className='card-xxl-stretch mb-5 mb-xl-8' tableTitle="Latest Entries For Asthmatic Users" />}
          {activeTab == "athlete" && <JournalAthleteTableComponent className='card-xxl-stretch mb-5 mb-xl-8' tableTitle="Latest Entries For Performance Athlete Users" />}


        </div>
      </div>
      {/* end::Row */}

    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }

