import clsx from "clsx";
import { FC } from "react";
import { DOTS, UsePagination } from "./usePagination";
import "./pagination.scss"

type Props = {
    className: string,
    onPageChange: any,
    totalCount: number,
    siblingCount: number,
    currentPage: number,
    pageSize: number
}
export const Pagination: FC<Props> = ({
    className,
    onPageChange,
    totalCount,
    siblingCount,
    currentPage,
    pageSize
}) => {

    const paginationRange: any = UsePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={clsx('pagination-container', { [className]: className })}
        >
            <li
                className={clsx('pagination-item', {
                    disabled: currentPage === 1
                })}
                onClick={onPrevious}
            >
                <img src="/media/icons/custom/pageprev.svg" alt="Page Prev"/>
            </li>
            {paginationRange.map((pageNumber: any) => {
                if (pageNumber === DOTS) {
                    return <li className="pagination-item dots">&#8230;</li>;
                }

                return (
                    <li
                        className={clsx('pagination-item', {
                            selected: pageNumber === currentPage
                        })}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={clsx('pagination-item', {
                    disabled: currentPage === lastPage
                })}
                onClick={onNext}
            >
                <img src="/media/icons/custom/pagenext.svg" alt="Page Next"/>
                
            </li>
        </ul>
    );
};