/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import React, {EventHandler, useEffect, useMemo, useState} from 'react'
import {OverlayTrigger, Popover, PopoverBody} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {FlowVolumeLoopComponent} from '../chart/FlowVolumeLoop'
import {Pagination} from '../pagination/pagination'
var fileDownload = require('js-file-download')

type Props = {
  className: string
  tableTitle: string
  customFilter?: any
}

const JournalTableComponent: React.FC<Props> = ({className, tableTitle, customFilter}) => {
  const DATA_URL = process.env.REACT_APP_API_URL + '/api/journal/index'
  const CSV_URL = process.env.REACT_APP_API_URL + '/api/journal/csv'

  const [currentPage, setCurrentPage] = useState(1)
  const [currentTableData, setCurrentTableData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [PageSize, setPageSize] = useState(10)
  const [pageInfo, setPageInfo] = useState('')
  const [flowChartInfo, setFlowChartInfo] = useState(undefined)

  // Table Rows Note Array Show State
  const [showMore, setShowMore] = useState({} as any)
  const toggleShowMore = (index: number) => {
    setShowMore((prevShowMore: any) => ({
      ...prevShowMore,
      [index]: !prevShowMore[index],
    }))
  }

  const handleChangePageSize = (event: any) => {
    setPageSize(event.target.value)
    loadData(event.target.value)
    setCurrentPage(1)
  }

  const downloadCsv = () => {
    var filter = {}
    setIsLoading(true)
    if (customFilter !== undefined) filter = customFilter
    axios
      .post(CSV_URL, {
        //"searchterm": "test",
        filter: filter,
        sorting: {
          column: 'register_date',
          direction: 'desc',
        },
      })
      .then(function (res) {
        fileDownload(res.data, 'export.csv')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const downloadRawCsv = (row: any) => {
    var filter = {}
    setIsLoading(true)
    if (customFilter !== undefined) filter = customFilter
    axios
      .post(CSV_URL, {
        filter: {...filter, id: row.id},
        sorting: {
          column: 'register_date',
          direction: 'desc',
        },
      })
      .then(function (res) {
        fileDownload(res.data, 'rawdata.csv')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const loadData = (pageSize: any) => {
    var filter = {}
    if (customFilter !== undefined) filter = customFilter
    axios
      .post(DATA_URL, {
        //"searchterm": "test",
        filter: filter,
        paginator: {
          page: currentPage,
          pageSize: pageSize,
        },
        sorting: {
          column: 'register_date',
          direction: 'desc',
        },
      })
      .then(function (res) {
        setIsLoading(false)
        setCurrentTableData(res.data.items)
        setTotalCount(res.data.total)
      })
      .finally(() => {
        setIsLoading(false)
      })

    setPageInfo(() => {
      const start = (currentPage - 1) * parseInt(pageSize)
      const end = start + parseInt(pageSize)
      return start.toString() + ' - ' + end.toString()
    })
  }
  useEffect(() => {
    setIsLoading(true)
    loadData(PageSize)
  }, [setIsLoading, currentPage])

  const handleShowFlowChart = (row: any) => {
    setFlowChartInfo(row)
  }
  const handleHideFlowChart = () => {
    setFlowChartInfo(undefined)
  }

  const keydownFunction = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleHideFlowChart()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keydownFunction, false)
    return () => {
      document.removeEventListener('keydown', keydownFunction, false)
    }
  }, [])

  return (
    <>
      {flowChartInfo != undefined ? (
        <FlowVolumeLoopComponent chartData={flowChartInfo} handleCloseEvent={handleHideFlowChart} />
      ) : (
        ''
      )}
      <div className={`cardd ${className} tableContainer`}>
        <div className='d-flex flex-stack'>
          <div>
            <h2
              style={{
                fontSize: '20px',
                fontWeight: '400',
                marginBottom: 21,
                opacity: 0.6,
                color: '#05070A',
              }}
            >
              {tableTitle}
            </h2>
          </div>
          <div>
            <img
              style={{cursor: 'pointer'}}
              alt='Download CSV Table'
              onClick={downloadCsv}
              src='/media/icons/custom/csv.svg'
            />
          </div>
        </div>
        {/* begin::Body */}
        <div className={clsx(isLoading ? 'overlay overlay-block' : '')}>
          <div className='overlay-wrapper'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-bordered align-middle gs-0 gy-4 myTable'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-gray-700'>
                    <th style={{minWidth: '175px'}}>
                      <span className='container'>Patient Name</span>
                    </th>
                    <th style={{minWidth: '100px'}}>Date</th>
                    <th style={{minWidth: '78px'}}>
                      FEV<sub>1</sub> / FVC
                    </th>
                    <th style={{minWidth: '58px'}}>
                      FEV<sub>1</sub>
                    </th>
                    <th style={{minWidth: '58px'}}>FVC</th>
                    <th className='text-center' style={{minWidth: '62px'}}>
                      <div className='tableHeaderIcon'>
                        <img src='/media/icons/custom/barometer.svg' alt='Barometer' />
                      </div>
                    </th>
                    <th className='text-center' style={{minWidth: '46px'}}>
                      <div className='tableHeaderIcon'>
                        <img src='/media/icons/custom/humidity.svg' alt='Humidity' />
                      </div>
                    </th>
                    <th className='text-center' style={{minWidth: '46px'}}>
                      <div className='tableHeaderIcon'>
                        <img src='/media/icons/custom/temperature.svg' alt='Temperature' />
                      </div>
                    </th>
                    <th className='text-center' style={{minWidth: '46px'}}>
                      <div className='tableHeaderIcon'>
                        <img src='/media/icons/custom/air.svg' alt='Air' />
                      </div>
                    </th>
                    <th className='text-center' style={{minWidth: '46px'}}>
                      <div className='tableHeaderIcon'>
                        <img src='/media/icons/custom/pollen.svg' alt='Pollen' />
                      </div>
                    </th>
                    <th style={{minWidth: '96px'}}>Symptoms</th>
                    <th style={{minWidth: '96px'}}>Cough</th>
                    <th style={{minWidth: '96px'}}>Wheezing</th>
                    <th style={{minWidth: '96px'}}>Allergies</th>
                    <th style={{minWidth: '96px'}}>Chest Pain</th>
                    <th style={{minWidth: '96px'}}>Anxiety</th>
                    <th style={{minWidth: '96px'}}>Shortness Of Breath</th>
                    <th style={{minWidth: '130px'}}>Location</th>
                    <th style={{minWidth: '130px'}}>Describe Location</th>
                    <th style={{minWidth: '130px'}}>Medications</th>
                    <th style={{minWidth: '130px'}}>Exposures</th>
                    <th style={{minWidth: '250px', maxWidth: '250px', width: '250px'}}>Notes</th>
                    <th style={{minWidth: '70px'}}>Raw Data</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {currentTableData && currentTableData.length > 0 ? (
                    currentTableData.map((row: any) => (
                      <tr key={row.id}>
                        <td className=''>
                          <span className='container px-4'>
                            <Link
                              className='text-primary fs-7'
                              to={`/patientdetail/${row.patient_unique_id}`}
                            >
                              {row.patient_name}
                            </Link>
                          </span>
                        </td>
                        <td
                          valign='top'
                          className='px-4 fs-7'
                          title={row.register_date != null ? row.register_date : ''}
                        >
                          {row.register_date != null && typeof row != undefined
                            ? new Date(row.register_date).toLocaleDateString('en-Us', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                              })
                            : '-'}
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          <div className='d-flex justify-content-between'>
                            {((parseFloat(row.fev1) / parseFloat(row.fvc)) * 100).toFixed(2)}{' '}
                            {row.flow_rate != undefined ? (
                              <img
                                style={{cursor: 'pointer'}}
                                onClick={() => handleShowFlowChart(row)}
                                src='/media/icons/custom/flow_chart.svg'
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          {parseFloat(row.fev1).toFixed(2)}
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          {parseFloat(row.fvc).toFixed(2)}
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          {row.barometric_pressure} inHg
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          {row.humidity}%
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          {row.temperature} F
                        </td>
                        <td valign='top' className='px-3 fs-7'>
                          {row.air_quality}
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          {row.pollen_count}
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          {row.symptoms ? 'Yes' : 'No'}
                        </td>
                        <td valign='top' className='px-4 fs-7 text-center'>
                          {row.symptoms_detail != null
                            ? row.symptoms_detail[1] != null
                              ? row.symptoms_detail[1] + ' / 5'
                              : ''
                            : ''}
                        </td>
                        <td valign='top' className='px-4 fs-7 text-center'>
                          {row.symptoms_detail != null
                            ? row.symptoms_detail[2] != null
                              ? row.symptoms_detail[2] + ' / 5'
                              : ''
                            : ''}
                        </td>
                        <td valign='top' className='px-4 fs-7 text-center'>
                          {row.symptoms_detail != null
                            ? row.symptoms_detail[3] != null
                              ? row.symptoms_detail[3] + ' / 5'
                              : ''
                            : ''}
                        </td>
                        <td valign='top' className='px-4 fs-7 text-center'>
                          {row.symptoms_detail != null
                            ? row.symptoms_detail[4] != null
                              ? row.symptoms_detail[4] + ' / 5'
                              : ''
                            : ''}
                        </td>
                        <td valign='top' className='px-4 fs-7 text-center'>
                          {row.symptoms_detail != null
                            ? row.symptoms_detail[5] != null
                              ? row.symptoms_detail[5] + ' / 5'
                              : ''
                            : ''}
                        </td>
                        <td valign='top' className='px-4 fs-7 text-center'>
                          {row.symptoms_detail != null
                            ? row.symptoms_detail[6] != null
                              ? row.symptoms_detail[6] + ' / 5'
                              : ''
                            : ''}
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          <div className='d-flex flex-stack'>
                            <div>{row.location_text}</div>
                            {row.location_lat == null && row.location_long == null ? (
                              <img
                                src='/media/icons/custom/map.svg'
                                style={{opacity: 0.3}}
                                alt='Location'
                              />
                            ) : (
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={`https://maps.google.com/?q=${row.location_lat},${row.location_long}`}
                              >
                                <img src='/media/icons/custom/map.svg' alt='Location' />
                              </a>
                            )}
                          </div>
                        </td>
                        <td valign='top'>{row.location_describe}</td>
                        <td valign='top' className='px-4 fs-7'>
                          {row.medications != null &&
                            (row.medications.length > 0 ? (
                              <OverlayTrigger
                                placement={'bottom'}
                                delay={{show: 0, hide: 100}}
                                overlay={
                                  <Popover>
                                    <PopoverBody>
                                      {row.medicationsTimesInfo != null
                                        ? row.medicationsTimesInfo.join(', ')
                                        : row.medications.join(',')}
                                    </PopoverBody>
                                  </Popover>
                                }
                              >
                                <span className='border-solid border-bottom border-gray-500'>
                                  {row.medications.length > 1
                                    ? row.medications.length + ' Medications'
                                    : row.medications.join(',')}
                                </span>
                              </OverlayTrigger>
                            ) : (
                              '-'
                            ))}
                        </td>
                        <td valign='top' className='px-4 fs-7'>
                          {row.exposures != null && row.exposures.length > 1 ? (
                            <OverlayTrigger
                              placement={'bottom'}
                              delay={{show: 0, hide: 100}}
                              overlay={
                                <Popover>
                                  <PopoverBody>{row.exposures.join(', ')}</PopoverBody>
                                </Popover>
                              }
                            >
                              <span className='border-solid border-bottom border-gray-500'>
                                {row.exposures.length} Exposures
                              </span>
                            </OverlayTrigger>
                          ) : row.exposures != null ? (
                            row.exposures.join(',')
                          ) : (
                            ''
                          )}
                        </td>
                        <td valign='top' className='px-4 fs-7' style={{maxWidth: '250px'}}>
                          <span className='text-wrap'>
                            {showMore[row.id] ? row.note : row.note && row.note.slice(0, 24)}
                            {row.note && row.note.length > 24 && !showMore[row.id] && (
                              <>
                                <span>...</span>
                                <span
                                  style={{color: '#BB2E39',cursor:'pointer'}}
                                  onClick={() => toggleShowMore(row.id)}
                                >
                                  &nbsp;(See More)
                                </span>
                              </>
                            )}
                            {row.note && row.note.length > 22 && showMore[row.id] && (
                              <>
                                <br /><br/>
                                <span
                                  style={{color: '#BB2E39',cursor:'pointer'}}
                                  onClick={() => toggleShowMore(row.id)}
                                >
                                  (See Less)
                                </span>
                              </>
                            )}
                          </span>
                        </td>
                        <td valign='top' className='px-4 fs-7  text-center'>
                          {row.all_raw_list != '' && row.all_raw_list != null && (
                            <img
                              style={{cursor: 'pointer', height: 24}}
                              alt='Download Raw CSV'
                              onClick={() => downloadRawCsv(row)}
                              src='/media/icons/custom/csv.svg'
                            />
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={16}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>

            <div className='tfoot d-flex flex-md-stack'>
              <div className='d-flex flex-md-stack'>
                <select
                  onChange={handleChangePageSize}
                  className='form-select form-select-sm mx-5'
                  aria-label='Select example'
                  value={PageSize}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                </select>
                <span className='paginateDescription'>
                  {pageInfo} of {totalCount} items
                </span>
              </div>
              <div>
                <Pagination
                  className='pagination-bar'
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  siblingCount={1}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
          {isLoading && (
            <div className='overlay-layer rounded bg-dark bg-opacity-5'>
              <div className='spinner-border text-danger' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          )}
          {/* end::Table container */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export {JournalTableComponent}
