/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FeedbackTableComponent } from '../../shared/Table/FeedbacksTable';


const FeedbackPage: FC = (props) => {
    const params = useParams();

    return (
        <>
            <Link className='text-dark' style={{ fontSize: 14, marginBottom: 35 }} to={"/"}><img src="/media/icons/custom/back.svg" /> Back to dashboard</Link>
            <br /><br /><br />

            {/* begin::Row */}
            <div className='row gy-5 gx-xl-8'>

                <div className='col-xl-12'>
                    <FeedbackTableComponent className='card-xxl-stretch mb-5 mb-xl-8' tableTitle="Feedback Entries" customFilter={{ patient_unique_id: params.id }} />
                </div>
            </div>
            {/* end::Row */}

        </>
    )
}


const FeedbackWrapper: FC = (props) => {
    return (
        <>
            <FeedbackPage />
        </>
    )
}

export { FeedbackWrapper };

