import { combineReducers } from 'redux'
import { all } from 'redux-saga/effects'
import * as auth from '../../app/modules/auth'
import { IAuthState } from '../../app/modules/auth'


export const rootReducer = combineReducers({
  auth: auth.reducer,
})

export interface RootState {
  auth: IAuthState;
}

export function* rootSaga() {
  yield all([
    auth.saga()
  ])
}
