/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core'
import { JournalTableComponent } from '../../shared/Table/JournalsTable';


const TriggerDetailPage: FC = (props) => {
    const params = useParams();

    const [identifiedDateTime, setIdentifiedDateTime] = useState("")

    const defaultPatient = {
        id: 0,
        patient_unique_id: 0,
        sync_id: "",
        patient_name: "",
        email: "",
        last_active: new Date(),
        diagnosis: "",
        medications: "",
        joined: new Date(),
        age: 0,
        inches: 0,
        feet: 0,
        weight: 0,
        birth_gender: "",
        race: "",
        identified_triggers: {},
        profile_picture: "/media/icons/custom/empty_avatar.svg",

        triggerAirPollenCount: 0,
        triggerAirQualityCount: 0,
        triggerChemicalsCount: 0,
        triggerCigaretteSmokeCount: 0,
        triggerColdAirCount: 0,
        triggerDustCount: 0,
        triggerExerciseCount: 0,
        triggerInfectionCount: 0,
        triggerMoldCount: 0,
        triggerPetHairCount: 0,
        triggerPollenCount: 0,
        triggerStressCount: 0,
    }


    const parseIcon = () => {

        let icon = "";

        switch (params.trigger) {
            case "Air Quality": {
                icon = "/media/icons/triggers/airquality.svg"
                break;
            }
            case "Chemicals": {
                icon = "/media/icons/triggers/chemicals.svg"
                break;
            }
            case "Stress": {
                icon = "/media/icons/triggers/stress.svg"
                break;
            }
            case "Cold Air": {
                icon = "/media/icons/triggers/coldair.svg"
                break;
            }
            case "Mold": {
                icon = "/media/icons/triggers/mold.svg"
                break;
            }
            case "Air Pollution": {
                icon = "/media/icons/triggers/airpollution.svg"
                break;
            }
            case "Cigarette Smoke": {
                icon = "/media/icons/triggers/cigarette.svg"
                break;
            }
            case "Exercise": {
                icon = "/media/icons/triggers/exercise.svg"
                break;
            }
            case "Pollen": {
                icon = "/media/icons/triggers/pollen.svg"
                break;
            }
            case "Pet Hair": {
                icon = "/media/icons/triggers/pethair.svg"
                break;
            }
            case "Dust": {
                icon = "/media/icons/triggers/dust.svg"
                break;
            }
            case "Infection": {
                icon = "/media/icons/triggers/infection.svg"
                break;
            }
            default: {
                break;
            }
        }

        return icon;

    }

    const DATA_URL = process.env.REACT_APP_API_URL + "/api/patient/view";
    const TRIGGER_URL = process.env.REACT_APP_API_URL + "/api/journal/triggerIdentified";
    const [isLoading, setIsLoading] = useState(false);
    const [currentPatientData, setCurrentPatientData] = useState(defaultPatient);

    let lastActiveFormatted = (date: any) => {

        //5:00 PM on March 4, 2022 

        if (date != null) {
            let fixedDate = new Date(date);

            let hours = fixedDate.getHours();
            let minutes = fixedDate.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';

            hours %= 12;
            hours = hours || 12;
            const newMinutes = minutes < 10 ? `0${minutes}` : minutes;

            const strTime = `${hours}:${newMinutes} ${ampm}`;

            return strTime + " on " + fixedDate.toLocaleDateString('en-Us', { month: 'short', day: 'numeric', year: 'numeric' })
        } else
            return "-";
    }

    useEffect(() => {
        var filter = { "patient_unique_id": params.id };
        axios.post(DATA_URL, {
            //"searchterm": "test",
            "filter": filter,
        }).then(function (res) {
            setIsLoading(false);
            console.log(res.data);
            if (res.data)
                setCurrentPatientData(res.data);
            else
                setCurrentPatientData(defaultPatient);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [DATA_URL, params]);

    useEffect(() => {
        var filter = { "patient_unique_id": params.id, trigger: params.trigger };
        axios.post(TRIGGER_URL, {
            //"searchterm": "test",
            "filter": filter,
        }).then(function (res) {
            setIsLoading(false);
            console.log(res.data);
            if (res.data)
                setIdentifiedDateTime(lastActiveFormatted(res.data.fifthOrLast));
            else
                setIdentifiedDateTime("");
        }).finally(() => {
            setIsLoading(false);
        })
    }, [DATA_URL, params]);

    return (
        <>
            <Link className='text-dark' style={{ fontSize: 14, marginBottom: 35 }} to={`/patientdetail/${currentPatientData.patient_unique_id}`}><img src="/media/icons/custom/back.svg" /> Back to {currentPatientData.patient_name}</Link>
            <br /><br />
            <div className='d-flex flex-nowrap userInfoContainer'>
                <div className='d-flex flex-nowrap leftSide'>
                    <div className=''>
                        <div className='d-flex align-items-baseline'>
                            <span className='headerTriggerIcon'><KTSVG path={parseIcon()} className='svg-icon-dark' /> </span>
                            <h1 className='patientName'>{params.trigger}</h1>
                            <span className='lastActive'>Identified on {identifiedDateTime}</span>
                        </div>
                        <div style={{ marginBottom: 32 }}><span>
                            We have found correlations between <b>{params.trigger}</b> and lower lung values.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* begin::Row */}
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xl-12'>
                    <JournalTableComponent className='card-xxl-stretch mb-5 mb-xl-8' tableTitle="Relevant Entries" customFilter={{ patient_unique_id: params.id, trigger: params.trigger }} />
                </div>
            </div>
            {/* end::Row */}

        </>
    )
}


const TriggerDetailWrapper: FC = (props) => {
    return (
        <>
            <TriggerDetailPage />
        </>
    )
}

export { TriggerDetailWrapper }

