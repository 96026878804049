/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import React, { EventHandler, useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Popover, PopoverBody } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { FlowVolumeLoopComponent } from '../chart/FlowVolumeLoop'
import { Pagination } from '../pagination/pagination'
var fileDownload = require('js-file-download');


type Props = {
  className: string,
  tableTitle: string,
  customFilter?: any,
}


const JournalAthleteTableComponent: React.FC<Props> = ({ className, tableTitle, customFilter }) => {

  const DATA_URL = process.env.REACT_APP_API_URL + "/api/journal_athlete/index"
  const CSV_URL = process.env.REACT_APP_API_URL + "/api/journal_athlete/csv"

  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [PageSize, setPageSize] = useState(10);
  const [pageInfo, setPageInfo] = useState("");
  const [flowChartInfo, setFlowChartInfo] = useState(undefined);


  const handleChangePageSize = (event: any) => {
    setPageSize(event.target.value);
    loadData(event.target.value)
    setCurrentPage(1);
  }

  const downloadCsv = () => {
    var filter = {};
    setIsLoading(true);
    if (customFilter !== undefined)
      filter = customFilter;
    axios.post(CSV_URL, {
      //"searchterm": "test",
      "filter": filter,
      "sorting": {
        "column": "pre_date_time",
        "direction": "desc"
      }
    }).then(function (res) {
      fileDownload(res.data, 'export.csv');
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const downloadRawCsv = (row: any) => {
    var filter = {};
    setIsLoading(true);
    if (customFilter !== undefined)
      filter = customFilter;
    axios.post(CSV_URL, {
      "filter": { ...filter, "id": row.id },
      "sorting": {
        "column": "pre_date_time",
        "direction": "desc"
      }
    }).then(function (res) {
      fileDownload(res.data, 'rawdata.csv');
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const loadData = (pageSize: any) => {
    var filter = {};
    if (customFilter !== undefined)
      filter = customFilter;
    axios.post(DATA_URL, {
      //"searchterm": "test",
      "filter": filter,
      "paginator": {
        "page": currentPage,
        "pageSize": pageSize
      },
      "sorting": {
        "column": "pre_date_time",
        "direction": "desc"
      }
    }).then(function (res) {
      setIsLoading(false);
      setCurrentTableData(res.data.items);
      setTotalCount(res.data.total)
    }).finally(() => {
      setIsLoading(false);
    })

    setPageInfo(() => {
      const start = ((currentPage - 1) * parseInt(pageSize));
      const end = start + parseInt(pageSize);
      return start.toString() + " - " + end.toString();
    });
  }
  useEffect(() => {
    setIsLoading(true);
    loadData(PageSize);
  }, [setIsLoading, currentPage]);

  const handleShowFlowChart = (row: any) => {
    setFlowChartInfo(row)
  }
  const handleHideFlowChart = () => {
    setFlowChartInfo(undefined)
  }

  const keydownFunction = (e: KeyboardEvent) => {
    if (e.key === "Escape") { handleHideFlowChart() }
  }

  const handleSelectedVitality = (row: any, need: string) => {
    if (row.selected_vitality)
      for (row of row.selected_vitality) {
        console.log(row)
        if (row.split(',')[0] === "[" + need + "]") {
          return row.split(',')[1]
        }
      }
    return "0"
  }

  useEffect(() => {
    document.addEventListener("keydown", keydownFunction, false);
    return () => { document.removeEventListener("keydown", keydownFunction, false); };
  }, []);

  return (
    <>
      {flowChartInfo != undefined ? <FlowVolumeLoopComponent chartData={flowChartInfo} handleCloseEvent={handleHideFlowChart} /> : ''}
      <div className={`cardd ${className} tableContainer`}>
        <div className='d-flex flex-stack'>
          <div>
            <h2 style={{ fontSize: "20px", fontWeight: "400", marginBottom: 21, opacity: .6, color: "#05070A" }}>{tableTitle}</h2>
          </div>
          <div>
            <img style={{ cursor: "pointer" }} onClick={downloadCsv} src='/media/icons/custom/csv.svg' />
          </div>
        </div>
        {/* begin::Body */}
        <div className={clsx((isLoading ? 'overlay overlay-block' : ''))}>
          <div className="overlay-wrapper">
            {/* begin::Table container */}
            <div className='table-responsive'>

              {/* begin::Table */}
              <table className='table table-bordered align-middle gs-0 gy-4 myTable'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-gray-700'>
                    <th style={{ minWidth: "175px" }}>
                      <span className="container">
                        Patient Name
                      </span></th>
                    <th style={{ minWidth: "100px" }}>Date</th>
                    <th style={{ minWidth: "120px" }}>Pre-Exercise Time</th>
                    <th style={{ minWidth: "78px" }}>FEV<sub>1</sub> / FVC</th>
                    <th style={{ minWidth: "58px" }}>FEV<sub>1</sub></th>
                    <th style={{ minWidth: "58px" }}>FVC</th>
                    <th className='text-center' style={{ minWidth: "62px" }}><div className='tableHeaderIcon'><img src='/media/icons/custom/barometer.svg' alt='Barometer' /></div></th>
                    <th className='text-center' style={{ minWidth: "46px" }}><div className='tableHeaderIcon'><img src='/media/icons/custom/humidity.svg' alt='Humidity' /></div></th>
                    <th className='text-center' style={{ minWidth: "46px" }}><div className='tableHeaderIcon'><img src='/media/icons/custom/temperature.svg' alt='Temperature' /></div></th>
                    <th className='text-center' style={{ minWidth: "46px" }}><div className='tableHeaderIcon'><img src='/media/icons/custom/air.svg' alt='Air' /></div></th>
                    
                    <th style={{ minWidth: "130px" }}>Location</th>
                    <th style={{ minWidth: "130px" }}>Describe Location</th>
                    
                    <th style={{ minWidth: "76px" }}>Energy</th>
                    <th style={{ minWidth: "96px" }}>Nutrition</th>
                    <th style={{ minWidth: "96px" }}>Hydration</th>
                    <th style={{ minWidth: "130px" }}>Sleep</th>
                    <th className='postBg' style={{ minWidth: "100px" }}>Post-Exercise Date</th>
                    <th className='postBg' style={{ minWidth: "120px" }}> Time</th>
                    <th className='postBg' style={{ minWidth: "78px" }}>FEV<sub>1</sub> / FVC</th>
                    <th className='postBg' style={{ minWidth: "58px" }}>FEV<sub>1</sub></th>
                    <th className='postBg' style={{ minWidth: "58px" }}>FVC</th>
                    <th className='postBg text-center' style={{ minWidth: "62px" }}><div className='tableHeaderIcon'><img src='/media/icons/custom/barometer.svg' alt='Barometer' /></div></th>
                    <th className='postBg text-center' style={{ minWidth: "46px" }}><div className='tableHeaderIcon'><img src='/media/icons/custom/humidity.svg' alt='Humidity' /></div></th>
                    <th className='postBg text-center' style={{ minWidth: "46px" }}><div className='tableHeaderIcon'><img src='/media/icons/custom/temperature.svg' alt='Temperature' /></div></th>
                    <th className='postBg text-center' style={{ minWidth: "46px" }}><div className='tableHeaderIcon'><img src='/media/icons/custom/air.svg' alt='Air' /></div></th>

                    <th className='postBg' style={{ minWidth: "58px" }}>Effort</th>
                    <th className='postBg' style={{ minWidth: "58px" }}>Distance </th>
                    <th className='postBg' style={{ minWidth: "120px" }}>Average Pace</th>
                    <th className='postBg' style={{ minWidth: "120px" }}>Average Heart Rate</th>
                    <th style={{ minWidth: "70px" }}>Raw Data</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {currentTableData && currentTableData.length > 0 ? (
                    currentTableData.map((row: any) => (
                      <tr key={row.id}>
                        <td className=''>
                          <span className="container px-4">
                            <Link className='text-primary fs-7' to={`/patientdetail/${row.patient_unique_id}`}>{row.patient_name}</Link>
                          </span>
                        </td>
                        <td className='px-4 fs-7' title={row.pre_date_time != null ? row.pre_date_time : ''}>{row.pre_date_time != null && typeof (row) != undefined ? new Date(row.pre_date_time).toLocaleDateString('en-Us', { month: 'short', day: 'numeric', year: 'numeric' }) : "-"}</td>
                        <td className='px-4 fs-7' title={row.pre_date_time != null ? row.pre_date_time : ''}>{row.pre_date_time != null && typeof (row) != undefined ? new Date(row.pre_date_time).toLocaleTimeString('en-Us', { hour: 'numeric', minute: 'numeric', hour12: true }) : "-"}</td>
                        <td className='px-4 fs-7'><div className='d-flex justify-content-between'>{((parseFloat(row.pre_fev1) / parseFloat(row.pre_fvc)) * 100).toFixed(2)}
                          {(row.pre_device_now_flow_rate != undefined ? <img alt="" style={{ cursor: "pointer" }} onClick={() => handleShowFlowChart({
                            time_delta: row.pre_device_time_delta,
                            flow_rate: row.pre_device_now_flow_rate,
                            total_flow: row.pre_device_total_flow
                          })} src='/media/icons/custom/flow_chart.svg' /> : '')}</div></td>
                        <td className='px-4 fs-7'>{parseFloat(row.pre_fev1).toFixed(2)}</td>
                        <td className='px-4 fs-7'>{parseFloat(row.pre_fvc).toFixed(2)}</td>
                        <td className='px-4 fs-7'>{row.pre_barometric_pressure} inHg</td>
                        <td className='px-4 fs-7'>{row.pre_humidity}%</td>
                        <td className='px-4 fs-7'>{row.pre_temperature} F</td>
                        <td className='px-3 fs-7'>{row.pre_air_quality}</td>

                        <td className='px-4 fs-7'>
                          <div className='d-flex flex-stack'>
                            <div>{row.pre_location_text}</div>
                              {(
                                row.pre_location_lat == null && row.pre_location_long == null ?
                                  <img src='/media/icons/custom/map.svg' style={{ opacity: .3 }} alt="Location" />
                                  :
                                  <a target="_blank" rel='noreferrer' href={`https://maps.google.com/?q=${row.pre_location_lat},${row.pre_location_long}`}>
                                    <img src='/media/icons/custom/map.svg' alt="Location" />
                                  </a>
                            )}
                          </div>
                        </td>
                        <td className='px-3 fs-7'>{row.pre_location_describe}</td> 

                        <td className='px-4 fs-7 text-center'>{handleSelectedVitality(row, "Energy Level")}/5</td>
                        <td className='px-4 fs-7 text-center'>{handleSelectedVitality(row, "Nutrition Level")}/5</td>
                        <td className='px-4 fs-7 text-center'>{handleSelectedVitality(row, "Hydration Level")}/5</td>
                        <td className='px-4 fs-7'>{row.selected_sleep}</td>

                        {row.progress == 'Completed' ? (<>
                          <td className='px-4 fs-7' title={row.post_date_time != null ? row.post_date_time : ''}>{row.post_date_time != null && typeof (row) != undefined ? new Date(row.post_date_time).toLocaleDateString('en-Us', { month: 'short', day: 'numeric', year: 'numeric' }) : "-"}</td>
                          <td className='px-4 fs-7' title={row.post_date_time != null ? row.post_date_time : ''}>{row.post_date_time != null && typeof (row) != undefined ? new Date(row.post_date_time).toLocaleTimeString('en-Us', { hour: 'numeric', minute: 'numeric', hour12: true }) : "-"}</td>
                          <td className='px-4 fs-7'><div className='d-flex justify-content-between'>{((parseFloat(row.post_fev1) / parseFloat(row.post_fvc)) * 100).toFixed(2)}
                            {(row.post_device_now_flow_rate != undefined ? <img alt='' style={{ cursor: "pointer" }} onClick={() => handleShowFlowChart({
                              time_delta: row.post_device_time_delta,
                              flow_rate: row.post_device_now_flow_rate,
                              total_flow: row.post_device_total_flow
                            })} src='/media/icons/custom/flow_chart.svg' /> : '')}</div></td>
                          <td className='px-4 fs-7'>{parseFloat(row.post_fev1).toFixed(2)}</td>
                          <td className='px-4 fs-7'>{parseFloat(row.post_fvc).toFixed(2)}</td>
                          <td className='px-4 fs-7'>{row.post_barometric_postssure} inHg</td>
                          <td className='px-4 fs-7'>{row.post_humidity}%</td>
                          <td className='px-4 fs-7'>{row.post_temperature} F</td>
                          <td className='px-3 fs-7'>{row.post_air_quality}</td>
                          <td className='px-3 fs-7'>{row.post_selected_effort ? row.post_selected_effort.substr(0, 1) + '/5' : '-'}</td>
                          <td className='px-3 fs-7'>{row.post_distance} miles</td>
                          <td className='px-3 fs-7'>{row.post_average_pace} mpg</td>
                          <td className='px-3 fs-7'>{row.post_average_hearth_rate} bpm</td>
                        </>) : (
                          <td className='px-4 fs-7' colSpan={13}>{row.progress}</td>
                        )}
                        <td className='px-4 fs-7  text-center'>
                          {
                            (row.pre_all_raw_list != "" && row.pre_all_raw_list != null) && (
                              <img style={{ cursor: "pointer", height: 24 }} alt="Download Raw CSV" onClick={() => downloadRawCsv(row)} src='/media/icons/custom/csv.svg' />)
                          }
                        </td>

                      </tr>
                    ))
                  ) : (<tr>
                    <td colSpan={16}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>)
                  }
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>

            <div className='tfoot d-flex flex-md-stack'>
              <div className='d-flex flex-md-stack'>
                <select onChange={handleChangePageSize} className="form-select form-select-sm mx-5" aria-label="Select example" value={PageSize}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span className='paginateDescription'>{pageInfo} of {totalCount} items</span>
              </div>
              <div>
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  siblingCount={1}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
              </div>
            </div>

          </div>
          {isLoading &&
            (<div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-danger"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>)
          }
          {/* end::Table container */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export { JournalAthleteTableComponent }

