/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Pagination } from '../pagination/pagination'
var fileDownload = require('js-file-download');


type Props = {
  className: string,
  tableTitle: string,
  customFilter?: any,
}


const FeedbackTableComponent: React.FC<Props> = ({ className, tableTitle, customFilter }) => {

  const DATA_URL = process.env.REACT_APP_API_URL + "/api/feedback/index"
  const CSV_URL = process.env.REACT_APP_API_URL + "/api/feedback/csv"

  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [PageSize, setPageSize] = useState(10);
  const [pageInfo, setPageInfo] = useState("");

  const handleChangePageSize = (event: any) => {
    setPageSize(event.target.value);
    loadData(event.target.value)
    setCurrentPage(1);
  }

  const downloadCsv = () => {
    var filter = {};
    setIsLoading(true);
    if (customFilter !== undefined)
      filter = customFilter;
    axios.post(CSV_URL, {
      //"searchterm": "test",
      "filter": filter,
      "sorting": {
        "column": "created_at",
        "direction": "asc"
      }
    }).then(function (res) {
      fileDownload(res.data, 'export.csv');
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const loadData = (pageSize: any) => {
    var filter = {};
    if (customFilter !== undefined)
      filter = customFilter;
    axios.post(DATA_URL, {
      //"searchterm": "test",
      "filter": filter,
      "paginator": {
        "page": currentPage,
        "pageSize": pageSize
      },
      "sorting": {
        "column": "created_at",
        "direction": "desc"
      }
    }).then(function (res) {
      setIsLoading(false); 
      setCurrentTableData(res.data.items);
      setTotalCount(res.data.total)
    }).finally(() => {
      setIsLoading(false);
    })

    setPageInfo(() => {
      const start = ((currentPage - 1) * parseInt(pageSize));
      const end = start + parseInt(pageSize);
      return start.toString() + " - " + end.toString();
    });
  }
  useEffect(() => {
    setIsLoading(true);
    loadData(PageSize);
  }, [setIsLoading, currentPage]);


  return (
    <div className={`cardd ${className} tableContainer`}>
      <div className='d-flex flex-stack'>
        <div>
          <h2 style={{ fontSize: "20px", fontWeight: "400", marginBottom: 21, opacity: .6, color: "#05070A" }}>{tableTitle}</h2>
        </div>
        <div>
          <img style={{ cursor: "pointer" }} onClick={downloadCsv} src='/media/icons/custom/csv.svg' />
        </div>
      </div>
      {/* begin::Body */}
      <div className={clsx((isLoading ? 'overlay overlay-block' : ''))}>
        <div className="overlay-wrapper">
          {/* begin::Table container */}
          <div className='table-responsive'>

            {/* begin::Table */}
            <table className='table table-bordered align-middle gs-0 gy-4 myTable'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-gray-700'>
                  <th style={{ minWidth: "175px", maxWidth: "175px", width: "175px" }}>
                    <span className="container">
                      Name
                    </span></th>
                  <th style={{ minWidth: "100px", maxWidth: "100px", width: "100px"  }}>Date</th>
                  <th style={{ minWidth: "85px", maxWidth: "85px", width: "85px"  }}>Time</th>
                  <th style={{ minWidth: "100px", maxWidth: "100px", width: "100px" }}>App Rating</th>
                  <th style={{ minWidth: "115px", maxWidth: "115px", width: "115px" }}>Device Rating</th>
                  <th>Comment</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {currentTableData && currentTableData.length > 0 ? (
                  currentTableData.map((row: any) => (
                    <tr key={row.id}>
                      <td className=''>
                        <span className="container px-4">
                          <Link className='text-primary fs-7' to={`/patientdetail/${row.patient_unique_id}`}>{row.patient_name}</Link>
                        </span>
                      </td>
                      <td className='px-4 fs-7' title={row.created_at != null ? row.created_at : ''}>{row.created_at != null && typeof (row) != undefined ? new Date(row.created_at).toLocaleDateString('en-Us', { month: 'short', day: 'numeric', year: 'numeric' }) : "-"}</td>
                      <td className='px-4 fs-7' title={row.created_at != null ? row.created_at : ''}>{row.created_at != null && typeof (row) != undefined ? new Date(row.created_at).toLocaleTimeString('en-Us', {hour:'numeric',minute:'numeric',hour12:true }) : "-"}</td>
                      <td className='px-4 fs-7 text-center'>{row.app_rate} / 5 </td>
                      <td className='px-4 fs-7 text-center'>{row.device_rate} / 5 </td>
                      <td className='px-4 fs-7 text-left'>{row.comment}</td>
                    </tr>
                  ))
                ) : (!isLoading && <tr>
                  <td colSpan={6}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>)
                }
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>

          <div className='tfoot d-flex flex-md-stack'>
            <div className='d-flex flex-md-stack'>
              <select onChange={handleChangePageSize} className="form-select form-select-sm mx-5" aria-label="Select example" value={PageSize}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
              <span className='paginateDescription'>{pageInfo} of {totalCount} items</span>
            </div>
            <div>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={PageSize}
                siblingCount={1}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </div>
          </div>

        </div>
        {isLoading &&
          (<div className="overlay-layer rounded bg-dark bg-opacity-5">
            <div
              className="spinner-border text-danger"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>)
        }
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { FeedbackTableComponent }

