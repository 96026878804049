/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef, useState } from 'react'

type Props = {
  className: string
  chartHeight: string,
  chartData: any,
}

const ChartWidget: React.FC<Props> = ({ className, chartHeight, chartData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {

    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartData))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chartData])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        <span className='chartDescription'>Number of users by # of entries</span>
        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartHeight: string, chartData: any): ApexOptions => {
  const labelColor = "#000"
  return {
    series: chartData.series || [],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40px',
        borderRadius: 0,
      },
    },
    legend: {
      show: true,
      position: "right"
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'datetime',
      categories: chartData.cols/*.map((r: any) => ((new Date(r)).toLocaleDateString('en-Us', { day: 'numeric', month: 'short' })))*/ || [],
      axisBorder: {
        show: true,
        color: "#000",
      },
      axisTicks: {
        show: false,
      },
      labels: {
        trim: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {  
      axisBorder: {
        show: true,
        color: "#000",
        width: 1
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
        formatter: (value) => value.toString()
      },
    },
    fill: {
      type: 'solid',
      opacity: 1
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + " Patient"+(val >1 ? 's':'')
        },
      },
    },
    colors: ["#BB2E39", "#C89F36"],
    grid: {
      padding: {
        top: 0,
      },
      borderColor: "#AAA",
      strokeDashArray: 0,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export { ChartWidget }

