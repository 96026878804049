/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core'
import { JournalAthleteTableComponent } from '../../shared/Table/JournalAthleteTable';
import { JournalTableComponent } from '../../shared/Table/JournalsTable';


const PatientDetailPage: FC = (props) => {
    const params = useParams();

    const defaultPatient = {
        id: 0,
        patient_unique_id: 0,
        sync_id: "",
        patient_name: "",
        email: "",
        last_active: new Date(),
        diagnosis: "",
        medications: "",
        joined: new Date(),
        age: 0,
        inches: 0,
        feet: 0,
        weight: 0,
        birth_gender: "",
        race: "",
        identified_triggers: {},
        profile_picture: "/media/icons/custom/empty_avatar.svg",

        triggerAirPollenCount: 0,
        triggerAirQualityCount: 0,
        triggerChemicalsCount: 0,
        triggerCigaretteSmokeCount: 0,
        triggerColdAirCount: 0,
        triggerDustCount: 0,
        triggerExerciseCount: 0,
        triggerInfectionCount: 0,
        triggerMoldCount: 0,
        triggerPetHairCount: 0,
        triggerPollenCount: 0,
        triggerStressCount: 0,
        otherDiagnosisName: "",
        otherMedicationsName: "",

        user_type: "",
    }

    const DATA_URL = process.env.REACT_APP_API_URL + "/api/patient/view";
    const [isLoading, setIsLoading] = useState(false);
    const [currentPatientData, setCurrentPatientData] = useState(defaultPatient);

    const parseMedications = (medications: string) => {
        const medicationDef = [
            "-",
            "Terbutalin",
            "Eformoterol",
            "Salbutamol",
            "Other: Specify"
        ];
        medicationDef[4] = currentPatientData.otherMedicationsName;
        return medications.split(",").map((r: string) => medicationDef[parseInt(r)] || "-").join(", ");
    }

    const parseDiagnosis = (diagnosis: string) => {
        const diagnosisDef = [
            "-",
            "Allergic Asthma", // 1
            "Non Allergic Asthma", // 2
            "Bronchoconstriction", // 3
            "Other: Specify", // 4
        ];
        diagnosisDef[4] = currentPatientData.otherDiagnosisName;
        return diagnosis.split(",").map((r: string) => diagnosisDef[parseInt(r)] || "-").join(", ");
    }

    const calcTriggerCount = (currentPatientData: any) => {
        let count = 0;
        if (currentPatientData.triggerAirQualityCount >= 5)
            count++;
        if (currentPatientData.triggerChemicalsCount >= 5)
            count++;
        if (currentPatientData.triggerStressCount >= 5)
            count++;
        if (currentPatientData.triggerColdAirCount >= 5)
            count++;
        if (currentPatientData.triggerMoldCount >= 5)
            count++;
        if (currentPatientData.triggerAirPollenCount >= 5)
            count++;
        if (currentPatientData.triggerCigaretteSmokeCount >= 5)
            count++;
        if (currentPatientData.triggerExerciseCount >= 5)
            count++;
        if (currentPatientData.triggerPollenCount >= 5)
            count++;
        if (currentPatientData.triggerPetHairCount >= 5)
            count++;
        if (currentPatientData.triggerDustCount >= 5)
            count++;
        if (currentPatientData.triggerInfectionCount >= 5)
            count++;

        return count;

    }

    let lastActiveFormatted = (date: any) => {

        //5:00 PM on March 4, 2022 

        if (date != null) {
            let fixedDate = new Date(date);

            let hours = fixedDate.getHours();
            let minutes = fixedDate.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';

            hours %= 12;
            hours = hours || 12;
            const newMinutes = minutes < 10 ? `0${minutes}` : minutes;

            const strTime = `${hours}:${newMinutes} ${ampm}`;

            return strTime + " on " + fixedDate.toLocaleDateString('en-Us', { month: 'short', day: 'numeric', year: 'numeric' })
        } else
            return "-";
    }

    useEffect(() => {
        var filter = { "patient_unique_id": params.id };
        axios.post(DATA_URL, {
            //"searchterm": "test",
            "filter": filter,
        }).then(function (res) {
            setIsLoading(false);
            console.log(res.data);
            if (res.data)
                setCurrentPatientData(res.data);
            else
                setCurrentPatientData(defaultPatient);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [DATA_URL, params]);

    return (
        <>
            <Link className='text-dark' style={{ fontSize: 14, marginBottom: 35 }} to={"/"}><img src="/media/icons/custom/back.svg" /> Back to dashboard</Link>
            <br /><br />

            <div className='d-flex flex-nowrap userInfoContainer'>
                <div className='d-flex flex-nowrap leftSide'>
                    <img className='patientPic' src={currentPatientData?.profile_picture || "/media/icons/custom/empty_avatar.svg"} alt="image" />
                    <div className=''>
                        <div className='d-flex align-items-baseline '>
                            <h1 className='patientName'>{currentPatientData?.patient_name || "-"}</h1>
                            <span className='lastActive'>Last Active {lastActiveFormatted(currentPatientData?.last_active)}</span>
                        </div>
                        {currentPatientData.user_type == "asthmatic" && (<>
                            <div className='d-flex'>
                                <span className='subHead'>Diagnosis</span>
                                <span className='subContent'>{parseDiagnosis(currentPatientData?.diagnosis || "")}</span>
                            </div>
                            <div className='d-flex'>
                                <span className='subHead'>Medications</span>
                                <span className='subContent'>{parseMedications(currentPatientData?.medications) || ""}</span>
                            </div>
                        </>)}
                        <div className='d-flex'>
                            <span className='subHead'>User Type</span>
                            <span className='subContent'>{(currentPatientData.user_type != "asthmatic" ? "Performance Athlete" : "Asthmatic")}</span>
                        </div>

                        {currentPatientData.user_type != "asthmatic" && (
                            <div className='d-flex'>
                                <span className='subHead'>Joined</span>
                                <span className='subContent'>{currentPatientData.joined != null ?
                                    new Date(currentPatientData.joined).toLocaleDateString('en-Us', { month: 'short', day: 'numeric', year: 'numeric' })
                                    : "-"}</span>
                            </div>)}
                    </div>
                </div>
                <div className='d-flex flex-nowrap rightSide'>
                    <div className=''>
                        {currentPatientData.user_type == "asthmatic" && (
                            <div className='d-flex'>
                                <span className='subHead'>Joined</span>
                                <span className='subContent'>{currentPatientData.joined != null ?
                                    new Date(currentPatientData.joined).toLocaleDateString('en-Us', { month: 'short', day: 'numeric', year: 'numeric' })
                                    : "-"}</span>
                            </div>)}
                        <div className='d-flex'>
                            <div className='d-flex'>
                                <span className='subHead'>Age</span>
                                <span className='subContent'>{currentPatientData?.age || "-"}</span>
                            </div>
                            <div className='d-flex'>
                                <span className='subHead'>Height</span>
                                <span className='subContent'>{currentPatientData.feet || "-"}'{(currentPatientData.inches || currentPatientData.inches === 0) ? currentPatientData.inches : "-"}"</span>
                            </div>
                            <div className='d-flex'>
                                <span className='subHead'>Weight</span>
                                <span className='subContent'>{currentPatientData.weight || "-"} lbs</span>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <span className='subHead'>Birth Gender</span>
                            <span className='subContent'>{currentPatientData?.birth_gender || "-"}</span>
                        </div>
                        <div className='d-flex'>
                            <span className='subHead'>Race</span>
                            <span className='subContent'>{currentPatientData?.race || "-"}</span>
                        </div>
                    </div>
                </div>
            </div>
            {currentPatientData.user_type != "asthmatic" && (<div style={{ height: 16 }}></div>)}
            {currentPatientData.user_type == "asthmatic" && (<div>
                <h2 style={{ fontSize: "20px", fontWeight: "400", marginBottom: 21, color: "#05070A", opacity: 0.6 }}>Identified Triggers ({calcTriggerCount(currentPatientData)}/12)</h2>
                <div className='identifiedTriggers'>
                    <div className='d-flex' style={{ marginBottom: -1 }}>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Air Quality`}
                            className={`iBox ${currentPatientData.triggerAirQualityCount < 5 ? 'passive' : ''}`}>
                            <span>Air Quality</span>
                            <span><KTSVG path='/media/icons/triggers/airquality.svg' className='svg-icon-dark' /> </span>
                        </Link>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Chemicals`}
                            className={`iBox ${currentPatientData.triggerChemicalsCount < 5 ? 'passive' : ''}`}>
                            <span>Chemicals</span>
                            <span><KTSVG path='/media/icons/triggers/chemicals.svg' className='svg-icon-dark' /> </span>
                        </Link>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Stress`}
                            className={`iBox ${currentPatientData.triggerStressCount < 5 ? 'passive' : ''}`}>
                            <span>Stress</span>
                            <span><KTSVG path='/media/icons/triggers/stress.svg' className='svg-icon-dark' /> </span>
                        </Link>
                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Cold Air`}
                            className={`iBox ${currentPatientData.triggerColdAirCount < 5 ? 'passive' : ''}`}>
                            <span>Cold Air</span>
                            <span><KTSVG path='/media/icons/triggers/coldair.svg' className='svg-icon-dark' /> </span>
                        </Link>
                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Mold`}
                            className={`iBox ${currentPatientData.triggerMoldCount < 5 ? 'passive' : ''}`}>
                            <span>Mold</span>
                            <span><KTSVG path='/media/icons/triggers/mold.svg' className='svg-icon-dark' /> </span>
                        </Link>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Air Pollution`}
                            className={`iBox ${currentPatientData.triggerAirPollenCount < 5 ? 'passive' : ''}`}>
                            <span>Air Pollution</span>
                            <span><KTSVG path='/media/icons/triggers/airpollution.svg' className='svg-icon-dark' /> </span>
                        </Link>
                    </div>

                    <div className='d-flex' style={{ marginBottom: 30 }}>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Cigarette Smoke`}
                            className={`iBox ${currentPatientData.triggerCigaretteSmokeCount < 5 ? 'passive' : ''}`}>
                            <span>Cigarette Smoke</span>
                            <span><KTSVG path='/media/icons/triggers/cigarette.svg' className='svg-icon-dark' /> </span>
                        </Link>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Exercise`}
                            className={`iBox ${currentPatientData.triggerExerciseCount < 5 ? 'passive' : ''}`}>
                            <span>Exercise</span>
                            <span><KTSVG path='/media/icons/triggers/exercise.svg' className='svg-icon-dark' /> </span>
                        </Link>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Pollen`}
                            className={`iBox ${currentPatientData.triggerPollenCount < 5 ? 'passive' : ''}`}>
                            <span>Pollen</span>
                            <span><KTSVG path='/media/icons/triggers/pollen.svg' className='svg-icon-dark' /> </span>
                        </Link>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Pet Hair`}
                            className={`iBox ${currentPatientData.triggerPetHairCount < 5 ? 'passive' : ''}`}>
                            <span>Pet Hair</span>
                            <span><KTSVG path='/media/icons/triggers/pethair.svg' className='svg-icon-dark' /> </span>
                        </Link>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Dust`}
                            className={`iBox ${currentPatientData.triggerDustCount < 5 ? 'passive' : ''}`}>
                            <span>Dust</span>
                            <span><KTSVG path='/media/icons/triggers/dust.svg' className='svg-icon-dark' /> </span>
                        </Link>

                        <Link to={`/triggerdetail/${currentPatientData.patient_unique_id}/Infection`}
                            className={`iBox ${currentPatientData.triggerInfectionCount < 5 ? 'passive' : ''}`}>
                            <span>Infection</span>
                            <span><KTSVG path='/media/icons/triggers/infection.svg' className='svg-icon-dark' /> </span>
                        </Link>
                    </div>
                </div>
            </div>)}

            {/* begin::Row */}
            <div className='row gy-5 gx-xl-8'>

                <div className='col-xl-12'>
                    {currentPatientData.user_type == "asthmatic" ?
                        <JournalTableComponent className='card-xxl-stretch mb-5 mb-xl-8' tableTitle="Latest Entries For This Patient" customFilter={{ patient_unique_id: params.id }} />
                        :
                        <JournalAthleteTableComponent className='card-xxl-stretch mb-5 mb-xl-8' tableTitle="Latest Entries For This Athlete" customFilter={{ patient_unique_id: params.id }} />
                    }</div>
            </div>
            {/* end::Row */}

        </>
    )
}


const PatientDetailWrapper: FC = (props) => {
    return (
        <>
            <PatientDetailPage />
        </>
    )
}

export { PatientDetailWrapper }

