// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from 'redux-persist/integration/react'
import { AppRoutes } from './app/routing/AppRoutes'
import * as _setup from './setup'
import store, { persistor } from './setup/redux/Store'
import './_metronic/assets/sass/style.react.scss'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'



_setup.setupAxios(axios, store)

Chart.register(...registerables)

const queryClient = new QueryClient()

//Firebase
const fbConfig = {
  apiKey: "AIzaSyD9Tc_ab7z3uLhtd9tpftaqiNq9l_xGrp0",
  authDomain: "argospire-220d6.firebaseapp.com",
  projectId: "argospire-220d6",
  storageBucket: "argospire-220d6.appspot.com",
  messagingSenderId: "415234886312",
  appId: "1:415234886312:web:65c49c3e1ec5fce104909d",
  measurementId: "G-FDBWSFH0YQ"
};



ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
          <AppRoutes />
        </PersistGate>
      </Provider>
    </MetronicI18nProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
)
